import React, { useEffect } from "react";
import * as S from '../../styles/default';
import Header from "../../components/header";
import Footer from "../../components/footer/footer";
import Main from "../../components/main";
import { AppRoute } from "../../const";


export default function MainScreen(): JSX.Element {
    useEffect(() => {
        document.title = 'Оферта. French withe Emillie'
    }, [])
    return (
        <React.Fragment>
            <Header background={false} />
            <Main>
                <S.Wrapper>
                    <S.TitleH1 >ДОГОВОР – ОФЕРТА</S.TitleH1>
                    <S.Text>Индивидуальный предприниматель ЛЕОНОВИЧ ЭМИЛИЯ ВЛАДИМИРОВНА, именуемый в дальнейшем Исполнитель, ОГРНИП: 323246800115786, ИНН: 246312003224, Дата присвоения ОГРНИП: 06.09.2023, использующий знак обслуживания «French With Emilie» адресует настоящий Договор-оферту (далее по тексту – Договор) любому лицу (неопределенному кругу лиц), чья воля будет выражена им лично либо через уполномоченного представителя (ст. 182, 185 Гражданского кодекса Российской Федерации), выразившему готовность принять предложение Исполнителя, на указанных ниже условиях.</S.Text>
                    <S.Text>В соответствии с п. 2 ст. 437 Гражданского кодекса РФ (далее по тексту – ГК РФ) в случае принятия изложенных ниже условий и оплаты услуг, лицо, производящее акцепт этой оферты становится Заказчиком (в соответствии с п. 3 ст. 438 ГК РФ акцепт оферты равносилен заключению договора на условиях, изложенных в оферте), а Исполнитель и Заказчик совместно Сторонами договора.</S.Text>
                    <S.Text>Публичная Оферта предполагает намерение Исполнителя оказывать услуги, предусмотренные настоящим Договором, всем лицам, обратившимся за оказанием услуг и принявшим все условия Оферты, в соответствии с настоящим Договором, кроме случаев, когда оказание услуг Исполнителем невозможно по техническим, правовым или иным причинам.</S.Text>
                    <S.Text>1.	<strong>Термины и определения</strong></S.Text>
                    <S.Text><strong>Заявка</strong> – намерение Заказчика воспользоваться услугами Исполнителя, выраженное в направлении электронного запроса по установленной форме на Сайте Исполнителя.</S.Text>
                    <S.Text><strong>Акцепт</strong> – полное и безоговорочное принятие всех условий данной Оферты Заказчиком путем осуществления действий, указанных в п. 2.1. Договора</S.Text>
                    <S.Text><strong>Пользователь</strong> - юридическое или дееспособное физическое лицо, а также индивидуальный предприниматель, заключивший Договор посредством акцепта, на условиях, содержащихся в оферте. В интересах настоящего Договора наименования «Заказчик» и «Пользователь» являются тождественными и обозначают контрагента, присоединившегося к настоящему Договору в порядке п. 3 ст. 438 ГК РФ.</S.Text>
                    <S.Text><strong>Технологии дистанционного предоставления информационных услуг</strong> – оказание информационных услуг дистанционно с использованием сети Интернет (полностью или частично).</S.Text>
                    <S.Text><strong>Личный кабинет</strong> – персональный раздел Пользователя на сайте Исполнителя, вход в который осуществляется посредством ввода учетных данных Пользователя, в котором Пользователю доступно управление отдельными сервисами сайта Исполнителя, в том числе их заказ, подключение, отключение, на предложенных Исполнителем условиях.</S.Text>
                    <S.Text><strong>Регистрация</strong> – совокупность действий Пользователя в соответствии с указанными на сайте Исполнителя инструкциями, включая предоставление учетных данных и иной информации, совершаемых Пользователем с использованием специальной формы пользовательского интерфейса сайта Исполнителя в целях формирования личного кабинета и получения доступа к отдельным сервисам сайта.</S.Text>
                    <S.Text><strong>Учетные данные</strong> – уникальный логин (адрес электронной почты) и пароль, создаваемые самостоятельно Пользователем в процессе регистрации личного кабинета либо измененные в дальнейшем и используемые для доступа в личный кабинет Пользователя.</S.Text>
                    <S.Text><strong>Официальный Сайт Исполнителя</strong> (далее по тексту <b>Сайт</b>) – совокупность связанных между собой веб-страниц, объединенных под одним доменным именем или ip-адресом, размещенный в сети интернет на платформе по адресу: www.progressme.ru, а также по адресу: www.edvibe.com, а также по адресу: www.frenchwithemilie.ru</S.Text>
                    <S.Text><strong>Оферта</strong> – настоящий документ (Договор), размещенный в сети интернет по адресу: www.frenchwithemilie.ru. В соответствии с Договором, слова оферта и Договор являются равнозначными. </S.Text>
                    <S.Text><strong>Услуги</strong> – комплекс методических рекомендаций Исполнителя.</S.Text>
                    <S.TitleH3>1. 	ПРЕДМЕТ ДОГОВОРА</S.TitleH3>
                    <S.Text>1.1. В соответствии с Договором Исполнитель обязуется предоставить Пользователю права, указанные в п. 5.3. Договора, на использование информационных материалов, размещенных на сайте Исполнителя (далее по тексту – информационные материалы), путем предоставления Пользователю прав доступа к Базам данных в порядке, предусмотренном разделом 4 Договора, а также оказать дополнительные услуги, в соответствии с п. 1.4. Договора.</S.Text>
                    <S.Text>1.2. Объем информационных материалов, предоставляемых Пользователю, устанавливается в рамках выбранного Пользователем тарифного плана, размещенного на веб-страницу сайта Исполнителя по адресу www.progressme.ru, а также по адресу: www.edvibe.com, в котором определяется перечень информационных материалов, а также период обучения, в течение которого информационный материал доступен Пользователю. Данные правила распространяются также на информационные материалы, размещенные Исполнителем на серверах zoom и bitrix24.</S.Text>
                    <S.Text>1.3. Доступ к информационным материалам, предоставляется после совершения акцепта, в порядке, предусмотренном п. 2.1. Договора в пределах времени, установленном в тарифном плане.</S.Text>
                    <S.Text> 1.4. В рамках предоставления доступа к информационным материалам, личному кабинету, в котором доступны изученные материалы и материалы, для изучения, Пользователю могут оказываться дополнительные услуги согласно тарифам и Правилам оказания услуг (Далее – Правила).</S.Text>
                    <S.Text>К дополнительным услугам относится:</S.Text>
                    <S.Text>1. одно домашнее задание к каждому из материалу и его проверка (Доступ к домашнему заданию можно получить, при учете что баланс клиента положительный, т.е. оплачено еще одно занятие)</S.Text>
                    <S.Text>3. промежуточное тестирование (по прохождению 20 часов занятий)</S.Text>
                    <S.Text>1.5. Дополнительные услуги, предусмотренные п. 1.4. Договора, оказываются на основании поданной Пользователем заявки, формируемой в порядке, предусмотренном п. 4.8. Договора.</S.Text>
                    <S.TitleH3>2. 	ПОРЯДОК ЗАКЛЮЧЕНИЯ ДОГОВОРА</S.TitleH3>
                    <S.Text>2.1. Акцептом Договора является оплата Пользователем выставленного Исполнителем счета в соответствии с изложенными в Договоре условиями в течение действия срока Акцепта. Оплата производится путем внесения денежных средств на расчетный счет Исполнителя безналично.</S.Text>
                    <S.Text>2.2. Осуществляя акцепт Договора в порядке, определенном в п. 2.1. Договора, Пользователь гарантирует, что ознакомлен, соглашается, полностью и безоговорочно принимает все условия Договора.</S.Text>
                    <S.Text>2.3. Пользователь понимает, что акцепт Договора равносилен заключению Договора на условиях, изложенных в Договоре.</S.Text>
                    <S.Text>2.4. Оферта вступает в силу с момента размещения в сети интернет по адресу: www.frenchwithemilie.ru и действует до даты начала уроков, установленной Правилами. </S.Text>
                    <S.Text>2.5. Осуществляя акцепт настоящего Договора, Пользователь гарантирует, что ознакомлен, соглашается, полностью и безоговорочно принимает все условия Договора и Правила в том виде, в каком они изложены в тексте Договора и Правил.</S.Text>
                    <S.Text>2.6. Настоящий Договор не требует скрепления печатями и (или) подписания Сторонами, сохраняя при этом полную юридическую силу.</S.Text>
                    <S.TitleH3>3. 	ПРАВА И ОБЯЗАННОСТИ СТОРОН</S.TitleH3>
                    <S.Text><strong>3.1. Пользователь обязуется:</strong></S.Text>
                    <S.Text>3.1.1. Производить оплату в порядке, размере и сроки, предусмотренные Договором и соответствующими Правилами.</S.Text>
                    <S.Text>3.1.2. Не передавать права доступа к информационным материалам третьим лицам и не использовать их иным образом, способным привести к нанесению ущерба интересам Исполнителя.</S.Text>
                    <S.Text>3.1.3. Хранить в тайне и не раскрывать третьим лицам информацию о своей учетной записи, дающей доступ в личный кабинет Пользователя. В случае если такая информация станет известна третьим лицам, Пользователь обязуется немедленно изменить пароль от учетной записи.</S.Text>
                    <S.Text>3.1.4. Не предпринимать каких-либо действий, которые могут привести к перебоям функционирования сайта Исполнителя, в том числе действий, которые связаны с повышенной нагрузкой на сайт.</S.Text>
                    <S.Text>3.1.5. Не использовать никаких приборов либо программ ЭВМ для вмешательства или попытки вмешательства в процесс нормального функционирования сайта Исполнителя.</S.Text>
                    <S.Text>3.1.6. По требованию Исполнителя предоставить последнему информацию и документы, необходимые для идентификации Пользователь, в том числе при направлении Пользователем в адрес Исполнителя заявлений, уведомлений и пр.</S.Text>
                    <S.Text>3.1.7. Соблюдать требования законодательства Российской Федерации и других нормативных актов Российской Федерации, а также Правила, размещенные на веб-странице www.frenchwithemilie.ru и условия Договора.</S.Text>
                    <S.Text>3.1.8. Самостоятельно отслеживать все изменения в условиях Договора и Правил путем ознакомления с их содержанием.</S.Text>
                    <S.Text><strong>3.2. Исполнитель обязуется:</strong></S.Text>
                    <S.Text>3.2.1. Предоставить Пользователю доступ к информационным материалам, путем предоставления доступа к защищенным страницам сайта Исполнителя.</S.Text>
                    <S.Text>3.2.2. Использовать все личные данные и иную конфиденциальную информацию о Пользователе только для выполнения свои обязательств по Договору, не передавать и не показывать третьим лицам, находящуюся у него документацию и информацию о Пользователе.</S.Text>
                    <S.Text>3.2.3. Предпринять все необходимые меры для защиты известных Исполнителю персональных данных Пользователя.</S.Text>
                    <S.Text>3.2.4. Консультировать Пользователя по всем вопросам функционирования и развития сайта Исполнителя.</S.Text>
                    <S.Text><strong>3.3. Пользователь вправе:</strong></S.Text>
                    <S.Text>3.3.1. После получения прав доступа к информационным материалам использовать их в объеме и порядке, установленном в Договоре.</S.Text>
                    <S.Text>3.3.2. Требовать от Исполнителя надлежащего исполнения Договора.</S.Text>
                    <S.Text>3.3.3. Обращаться к Исполнителю по всем вопросам, связанным с исполнением договора.</S.Text>
                    <S.Text>3.3.4. В соответствии с п. 1.5. Договора и в течение предоставленного доступа к информационным материалам подавать заявку на оказание дополнительных услуг в рамках тарифного плана, установленного Правилами.</S.Text>
                    <S.Text><strong>3.4. Исполнитель вправе:</strong></S.Text>
                    <S.Text>3.4.1. Вносить, редактировать или удалять с сайта Исполнителя любую информацию.</S.Text>
                    <S.Text>3.4.2. Приостанавливать работу сайта для проведения профилактических работ, не более 1-го раза в месяц.</S.Text>
                    <S.Text>3.4.3. В любое время в одностороннем (внесудебном) порядке вносить дополнения и изменения в условия Договор. Пользование сайтом Исполнителя после вступления в силу дополнений и (или) изменений Договора означает согласие Пользователя со всеми дополнениями и (или) изменениями.</S.Text>
                    <S.Text>3.4.4. Получать от Пользователя любую информацию, необходимую для выполнения своих обязательств по Договору. В случае непредставления либо неполного или неверного представления Пользователем информации, Исполнитель вправе приостановить или прекратить доступ Пользователя к информационным материалам.</S.Text>
                    <S.Text>3.4.5. Приостанавливать или прекратить регистрацию и доступ Пользователя в личный кабинет, если Исполнитель будет обоснованно считать, что Пользователь веден неправомерную деятельность, нарушает условия Договора или Правила.</S.Text>
                    <S.Text>3.5. Каждая Сторона настоящего Договора заявляет и гарантирует, что она имеет все права и полномочия, необходимые для заключения настоящего Договора и полного исполнения своих обязательств по нему, и что заключение и (или) исполнение обязательств по Договору не нарушит условия каких-либо других обязательств этой Стороны перед третьими лицами.</S.Text>
                    <S.Text>3.6. Оплата производится через сервис Sberpay по QR коду, указанному в акте, либо по реквизитам на расчетный счет Исполнителя. Исполнитель не несет ответственность за возможные технические неполадки, возникшие по вине операторов платежных систем и сервисов. Обязанность Заказчика по оплате считается исполненной с момента поступления денежных средств на счет Исполнителя.</S.Text>
                    <S.TitleH3>4. 	ПОРЯДОК ПРЕДОСТАВЛЕНИЯ ПРАВ ДОСТУПА К ИНФОРМАЦИОННЫМ МАТЕРИАЛАМ И ОКАЗАНИЯ УСЛУГ</S.TitleH3>
                    <S.Text>4.1. Оформление Пользователем права доступа к информационным материалам, осуществляется при условии обязательной регистрации Пользователя на сайте Исполнителя, а после регистрации - при условии ввода Пользователем идентификационных данных, к которым относятся логин и пароль.</S.Text>
                    <S.Text>4.2. Пользователь выбирает и оформляет объем и порядок доступа к информационным базам данных путем направления заявки на электронную почту Исполнителя.</S.Text>
                    <S.Text>4.3. Исполнитель регистрирует личный кабинет Пользователя и высылает ссылку на почту, содержащую логин и пароль для доступа в личный кабинет.</S.Text>
                    <S.Text>4.4. Учет времени действия, объема прав доступа Пользователя к информационным ресурсам, а также их учет, ведет операционная система исполнителя в личном кабинете Пользователя. Настоящим Пользователь подтверждает и дает свое согласие на то, что статистические данные операционной системы Исполнителя являются достаточными для подтверждения факта предоставления факта доступа к информационным материалам.</S.Text>
                    <S.Text>4.5. В соответствии с выбранным Пользователем порядком и объемом доступа к информационным услугам, Исполнитель берет на себя обязательства предоставить Пользователю доступ к информационным материалам, с момента подключения тарифного плана (акцепта Договора) в течение установленного времени, вплоть до зафиксированных в тарифных планах максимальных объемов, а Пользователь принимает обязательства по оплате выбранного объема баз данных, вне зависимости использования предоставленных прав.</S.Text>
                    <S.Text>4.6. Доступ к информационным материалам, открывается в личном кабинете Пользователя, вход в который осуществляется посредством ввода учетных данных Пользователя.</S.Text>
                    <S.Text>4.7. Письменные и устные консультации по вопросам пользования сайтом Исполнителя, а также дополнительные услуги по изучению баз данных оказываются Исполнителем в течение времени прохождения обучения, начиная с даты указанной в Правилах и в расписании на сайте Исполнителя.</S.Text>
                    <S.Text>4.8. Удаленный доступ к информационным базам данных по каналам связи осуществляется Пользователем в течение времени установленного Правилами с момента получения доступа к серверу Пользователя и сопровождается оказанием дополнительных услуг в порядке п. 1.4. Договора во временные рамка с момента для начала оказания информационных услуг в порядке, определяемом Правилами. Удаленный доступ к информационным базам данных Исполнителя может требовать установления дополнительного программного обеспечения на устройство Пользователя.</S.Text>
                    <S.Text>4.9. Сервер, устройство, параметры канала связи, пропускная способность канала связи, стабильность передачи данных Пользователя должны удовлетворять техническим требованиям достаточным для получения и воспроизведения информационных баз данных.</S.Text>
                    <S.Text>4.10. Исполнитель не несет ответственность:</S.Text>
                    <S.Text>- за устаревшие, бракованные или вышедшие из строя части сервера Пользователя;</S.Text>
                    <S.Text>- за работоспособность представленного Пользователем сервера;</S.Text>
                    <S.Text>- за совместимость сервера, предоставляемого Пользователем, с компонентами системы и прочими компонентами, необходимыми для работы устанавливаемых устройств;</S.Text>
                    <S.Text>- за безопасность канала связи, а также за любые последствия действий вредоносных программ, которые могли попасть через канал связи на мощности Пользователя.</S.Text>
                    <S.Text>4.11. Доступ к информационным материалам считается предоставленным, а права на их использование переданными, в том числе если Пользователь в разумный срок не предъявит Исполнителю письменных требований о ненадлежащем исполнении Договора.</S.Text>
                    <S.Text>4.12. Пользователю юридическому лицу и индивидуальному предпринимателю, для подтверждения передачи прав пользования информационного материала, передается Акт приема-передачи (далее по тексту – Акт).</S.Text>
                    <S.Text>4.13. Акт, формируется на основании данных определяемой операционной системой Исполнителя, которая ведет автоматический учет доступа и поступивших в счет оплаты денежных средств.</S.Text>
                    <S.Text>4.14. Акт, предусмотренный п. 4. 12 Договора, предоставляется Пользователю посредством его отправки на электронную почту Пользователя по его заявке. Отправка акта происходит на адрес электронной почты, указанный Пользователем при регистрации.</S.Text>
                    <S.Text>4.15. Факт оказания дополнительных услуг, предусмотренных п. 1.4. Договора, подтверждается Актом об оказании услуг, предоставляемый Пользователю ежемесячно в срок до 10 числа следующего месяца, за которым оказывались услуги.</S.Text>
                    <S.Text>4.16. Акт об оказании услуг передается Пользователю в порядке, установленном п. 4.14. Договора, при этом предусмотренные 4.15 и 4. 12 акты могут содержаться в одном документе, подтверждающим факт передачи прав использования информационных материалов и факт оказания услуг.</S.Text>
                    <S.Text>4.17. Вместе с Актами Исполнитель может предоставлять Пользователю иные документы, предоставление которых предусмотрено Правилами и настоящим Договором.</S.Text>
                    <S.Text>4.18. Наименование (Фамилия, Имя, Отчество), юридический адрес, почтовый адрес, ИНН, КПП, контактная и прочая информация в платежных документах, акта и других документах, формируемых Исполнителем в соответствии с Договором, указываются в соответствии с реквизитами, указанными в личном кабинете Пользователя.</S.Text>
                    <S.Text>4.19. Акты, предусмотренные п. 4.13., и п. 4.16. Договора, Пользователь обязан подписать и в срок пять дней с момента получения Акта, вернуть его Исполнителю или направить Исполнителю мотивированный отказ от и подписания.</S.Text>
                    <S.Text>4.20. В случае неполучения Исполнителем подписанного Пользователем Акта об оказании услуг или мотивированного отказа от его подписания, направленного в разумный срок, услуги считаются оказанными в полном объеме и надлежащим образом.</S.Text>
                    <S.TitleH3>5. 	ПРАВА НА РЕЗУЛЬТАТЫ ИНТЕЛЛЕКТУАЛЬНОЙ ДЕЯТЕЛЬНОСТИ</S.TitleH3>
                    <S.Text>5.1. Все исключительные права на объекты интеллектуальной собственности, доступные на сайте Исполнителя, в том числе методические рекомендации, уроки, а также элементы дизайна, текст, графические изображения, иллюстрации, видео, программы для ЭВМ, базы данных, музыка, звуки и другие объекты, размещенные на сайте Исполнителя, а равно составные части и их элементы, являются объектами исключительных прав Исполнителя и других правообладателей.</S.Text>
                    <S.Text>5.2. Отчуждение (продажа) прав не является предметом Договора</S.Text>
                    <S.Text>5.3. По Договору Исполнитель предоставляет Пользователю право функционального использования информационного материала, размещенного на сайте Исполнителя, на условиях простой (неисключительной) лицензии, которая включает в себя:</S.Text>
                    <S.Text>- доступ, использование, ознакомление с информационными материалами для целей обучения;</S.Text>
                    <S.Text>- доступ, использование, ознакомление, а также использование результатов работы на информационных ресурсах и базах данных Исполнителя для собственных нужд Пользователя.</S.Text>
                    <S.Text>5.4. Использование информационных материалов, размещенных на сайте Исполнителя иными способами, в том числе путем копирования (воспроизведения) информационных материалов, а также входящих в его состав элементов дизайна, программ для ЭВМ и баз данных, их декомпиляция, модификация, и последующее распространение, публичный показ, доведение до всеобщего сведения (кроме сведений, предоставляемых самим Пользователем), строго запрещены, если иное не предусмотрено Договором или соглашением с Пользователем.</S.Text>
                    <S.Text>5.5. Срок предоставления прав использования информационных материалов, размещенных на сайте Исполнителя, устанавливается в пределах времени, в течение которого информационный материал доступен Пользователю, в порядке, предусмотренном Договором и Правилами.</S.Text>
                    <S.Text>5.6. Использование Пользователем сайта Исполнителя, а также размещенного информационного материала допускается при условии сохранения всех знаком охраны авторского права, смежных прав, товарного знаков, сохранения имени (или псевдонима) автора/наименования правообладателя в неизменном виде, сохранении соответствующего объекта в неизменном виде. Исключение составляют случаи, прямо предусмотренные законодательством Российской Федерации или дополнительными соглашениями.</S.Text>
                    <S.Text>5.7. Сайт Исполнителя может содержать ссылки на сайте третьих лиц. Указанные сайте и их контент не проверяется Исполнителем на соответствие достоверности, полноты, законности и т.п. Исполнитель не несет ответственности за любую информацию, материалы, размещенные на сайтах третьих лиц, к которым Пользователь получает доступ в связи с использованием сайте Исполнителя.</S.Text>
                    <S.Text>5.8. Ссылка на сайт третьих лиц, услугу, продукт и любую информацию коммерческого или некоммерческого характера, размещенная на сайте Исполнителя не является одобрением или рекомендацией данных продуктов, работ, услуг Исполнителем, если это прямо не указывается Исполнителем.</S.Text>
                    <S.TitleH3>6. 	СТОИМОСТЬ И ПОРЯДОК РАСЧЕТОВ</S.TitleH3>
                    <S.Text>6.1. Вознаграждение Исполнителя по Договору определяется в соответствии с выбранным Пользователем тарифным планом, согласно Правил оказания услуг. Выбранный тарифный план определяет объем предоставленных Исполнителем прав, на использование информационных материалов, путем предоставления Пользователю прав доступа к Базам данных.</S.Text>
                    <S.Text>6.2. Оплата по Договору осуществляется в порядке 100 (сто)-процентной предоплаты.</S.Text>
                    <S.Text>6.3. Способ оплаты по Договору:</S.Text>
                    <S.Text>- перечисление Пользователем денежных средств в валюте Российской Федерации (рубль) на расчетный счет Исполнителя;</S.Text>
                    <S.Text>- перечисление Пользователем денежных средств в валюте Российской Федерации (рубль) с помощью системы примем платежей, в соответствии с указаниями на сайте Исполнителя.</S.Text>
                    <S.Text>При этом обязанности Пользователя в части оплаты считаются исполненными в момент зачисления денежных средств на расчетный счет Исполнителя либо при оплате с использованием электронных денежных средств, в момент получения от соответствующей платежной системы верифицированной информации о поступлении платежа.</S.Text>
                    <S.Text>Выбор способа оплаты производится Пользователем по своему усмотрению.</S.Text>
                    <S.Text>6.4. Оплата Пользователем производится с указанием номера Пользователя, указанного в личном кабинете, наименования и ИНН (для юридических лиц и индивидуальных предпринимателей) и (или) иных реквизитов, идентифицирующих платеж. При оплате безналичным переводом на основании выставленного счета, в платежном документе также указываются данные выставленного счета.</S.Text>
                    <S.Text>6.5. При отсутствии данных указанных в п. 6.4 Договора, позволяющих определить наименование платежа и (или) лица, совершившего платеж, Исполнитель вправе самостоятельно идентифицировать платеж согласно данным собственного учета, а в случае невозможности, считать обязательства по оплате невыполненными. При этом Исполнитель не несет ответственности за убытки, возникшие у Пользователя и (или) третьих лиц, в связи с неоказанием Пользователем правильных данных.</S.Text>
                    <S.Text>6.6. В рамках проведения стимулирующих мероприятий (акций) могут предоставляться скидки на оплату (бонусы), призы и пр. Проведение таких стимулирующих мероприятий (акций) и условия предоставления скидок (бонусов) и /или призов регулируется специальными правилами, публикуемыми на сайте Исполнителя, или соглашениями с Пользователем.</S.Text>
                    <S.Text>6.7. Исполнитель вправе в одностороннем порядке и без уведомления об этом Пользователя изменить стоимость тарифных планов. Продление использования ранее оформленного тарифного плана по новым ценам означает согласие Пользователя с такими изменениями.</S.Text>
                    <S.Text>Оплата Пользователем осуществляется по действующим на момент оплаты тарифам.</S.Text>
                    <S.TitleH3>7. 	ПЕРСОНАЛЬНЫЕ ДАННЫЕ</S.TitleH3>
                    <S.Text>7.1. Для выполнения условий Договора Пользователь соглашается предоставить и дает согласие на обработку персональных данных в соответствии с Федеральным законом от 27.07.2006 года № 152- ФЗ «О персональных данных» на условиях и для целей выполнения условий Договора. Под «персональными данными» понимается персональная информация, которую Пользователь предоставляет о себе самостоятельно для совершения акцепта.</S.Text>
                    <S.Text>7.2. Исполнитель гарантирует конфиденциальность в отношении персональных данных Пользователя и предоставляет доступ к персональным данным только тем сотрудникам, которым эта информация необходима для выполнения условий Договора, обеспечивая соблюдение указанными лицами конфиденциальности персональных данных и безопасности персональных данных при их обработке.</S.Text>
                    <S.TitleH3>8. 	ОТВЕТСТВЕННОСТЬ СТОРОН</S.TitleH3>
                    <S.Text>8.1. Стороны несут ответственность за неисполнение или ненадлежащее исполнение своих обязательств по Договору в соответствии с Договором и законодательством России.</S.Text>
                    <S.Text>8.2. Исполнитель не несет ответственности и не отвечает за возможные убытки, причиненные Пользователю в случае:</S.Text>
                    <S.Text>8.2.1. Технологических неисправностей каналов связи общего пользования, посредством которых осуществляется доступ к сайту Исполнителя или утраты доступа в сеть интернет;</S.Text>
                    <S.Text>8.2.2. Ошибок, пропусков, перерывов в работе, удаления файлов, дефектов, задержек в работе или передаче данных и других причин технологического характера, возникших не по вине Исполнителя;</S.Text>
                    <S.Text>8.2.3. Несанкционированного доступа к личному кабинету Пользователя;</S.Text>
                    <S.Text>8.2.4. Иных мошеннических действий третьих лиц;</S.Text>
                    <S.Text>8.2.5. Ошибки Пользователя при совершении платежей;</S.Text>
                    <S.Text>8.2.6. Иных технических и иных неполадок, наступивших не по вине Исполнителя, в результате которых доступ Пользователя в личный кабинет пользователя становится недоступным.</S.Text>
                    <S.Text>8.2.7. За неполадки в функционировании информационных баз данных размещенных в личном кабинете, наступившие по вине сторонних информационных сервисов.</S.Text>
                    <S.Text>8.3. Пользователь самостоятельно несет ответственность за соответствие содержания размещаемых им материалов на сайте Исполнителя требованиям действующего законодательства, включая ответственность перед третьими лицами.</S.Text>
                    <S.Text>8.4. Любая информация и (или) материалы, доступ к которым получает Пользователь с использованием сайта Исполнителя, Исполнитель использует на свой собственный риск и самостоятельно несет ответственность за возможные последствия использования указанной информации и (или) материалов, в том числе за ущерб, причиненный себе и третьим лицам.</S.Text>
                    <S.Text>8.5. В случае причинения убытков по вине Исполнителя, Исполнитель несет перед Пользователем ответственность в сумме, не превышающей стоимость оплаченного за соответствующий период вознаграждения.</S.Text>
                    <S.TitleH3>9. 	ОСНОВАНИЯ ИЗМЕНЕНИЯ И РАСТОРЖЕНИЯ ДОГОВОРА</S.TitleH3>
                    <S.Text>9.1. Договор может быть расторгнут по соглашению Сторон, а также в одностороннем порядке по письменному требованию одной из Сторон по основаниям, предусмотренным Договором и законодательством.</S.Text>
                    <S.Text>9.2. Расторжение Договора в одностороннем порядке производится в течение 10 календарных дней со дня получения Стороной такого требования.</S.Text>
                    <S.Text>9.3. В случае нарушения Пользователем условий Договора, Правил и действующего законодательства, Исполнитель вправе в одностороннем (внесудебном) порядке приостановить и ограничить доступ Пользователя к личному кабинету и информационным материалам, размещенным на сайте Исполнителя либо расторгнуть Договор, заблокировав доступ Пользователя к личному кабинету с одновременным прекращением полного доступа к информационным материалам, без специального уведомления об этом Пользователя.</S.Text>
                    <S.Text>9.4. Исполнитель вправе в любое время в одностороннем (внесудебном) порядке внести изменения в условия Договора. Изменения в условия Договора вступают в силу с момента их размещения способом, установленном для размещения оферты.</S.Text>
                    <S.Text>9.5. Исполнитель вправе в любое время в одностороннем порядке отказаться от исполнения Договора как полностью, так и в части, с уведомлением об этом Пользователя через интерфейс Исполнителя, по адресу электронной почты или иным способом.</S.Text>
                    <S.TitleH3>10. 	РАЗРЕШЕНИЕ СПОРОВ ИЗ ДОГОВОРА</S.TitleH3>
                    <S.Text>10.1. Претензионный порядок является обязательным. Спор может быть передан на разрешение суда после принятия сторонами мер по досудебному урегулированию по истечении тридцати календарных дней со дня направления претензии.</S.Text>
                    <S.Text>10.2. Все споры и разногласия, возникшие из Договора, передаются на рассмотрение в  Тимирязевский районный суд города Москвы, (Дмитровское ш., 65, Москва, 127238), а в случае подведомственности арбитражного суда – в Арбитражный суд города Москвы.</S.Text>
                    <S.Text>10.3. Стороны признают, что документы, отправленные посредством электронной почты или иными электронными средствами связи, в том числе посредством Личного кабинета, имеют юридическую силу, равноценную документами при обычном бумажном документообороте.</S.Text>
                    <S.TitleH3>11. 	ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ (ФОРС-МАЖОР)</S.TitleH3>
                    <S.Text>11.1. Стороны освобождаются от ответственности за полное или частичное неисполнение обязательств по Договору в случае, если неисполнение обязательств явилось следствием действий непреодолимой силы, а именно: пожара, наводнения, землетрясения, забастовки, войны, действий органов государственной власти или других независящих от Сторон обстоятельств.</S.Text>
                    <S.Text>11.2. Сторона, которая не может выполнить обязательства по Договору, должна своевременно, но не позднее пяти календарных дней после наступления обстоятельств непреодолимой силы, письменно известить другую Сторону, с предоставлением обосновывающих документов, выданных компетентными органами.</S.Text>
                    <S.Text>11.3. Стороны признают, что неплатежеспособность не является форс-мажорным обстоятельством.</S.Text>
                    <S.TitleH3>12. 	ПРОЧИЕ УСЛОВИЯ ДОГОВОРА</S.TitleH3>
                    <S.Text>12.1. Любое взаимодействие Сторон осуществляется помимо способов перечисленных в п. 10.3. также через Личный кабинет Пользователя. Пользователь получает задания, рекомендации и разъяснения Исполнителя в соответствующих разделах Личного кабинета и самостоятельно определяет способ, и время для их выполнения в соответствии с условиями Правил.</S.Text>
                    <S.Text>12.2. Стороны понимают, что предметом настоящего Договора является условия и порядок предоставление прав на использование информационных материалов, размещенных на сайте Исполнителя путем предоставления Пользователю прав доступа к Базам данных. Также стороны пришли к пониманию того, что настоящий Договор считается исполненным надлежащим образом и в полном объеме с момента получения доступа Пользователем в Личном кабинете информационных материалов Исполнителя. В случае невозможности Пользователя участвовать в онлайн уроках, предоставление Пользователю доступа к записи урока является надлежащим образом оказанной услугой.</S.Text>
                    <S.Text>12.3. В случае исключения Пользователя из числа участников уроков настоящий Договор считается расторгнутым. В случае, если Пользователь не был допущен к участию в уроке, согласно условиям Правил, настоящий договор считается незаключенным.</S.Text>
                    <S.Text>12.4. Любые материалы, полученные Пользователем по электронной почте или опубликованные на Сайте, предназначены для частного некоммерческого использования. Пользователь не имеет права копировать, передавать, отправлять по почте, и/или издавать материалы с Сайта и информационных и/или аналитических продуктов без письменного разрешения Исполнителя, а также не имеет права использовать их для массового распространения.</S.Text>
                    <S.Text>12.5. Ни одна из Сторон не может переуступить свои права и обязанности по Договору третьим лицам.</S.Text>
                    <S.Text>12.6. Пользователь соглашается и признает, что внесение изменений в Оферту влечет за собой внесение этих изменений в заключенный и действующий между Пользователем и Исполнителем Договор, и эти изменения в Договоре вступают в силу одновременно с такими изменениями в Оферте.</S.Text>
                    <S.Text>12.7. В случае отзыва Оферты Исполнителем в течение срока действия Договора Договор считается прекращенным с момента отзыва, если иное не оговорено Исполнителем при отзыве Оферты.</S.Text>
                    <S.Text>13. 	РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ И КОНТАКТНАЯ ИНФОРМАЦИЯ</S.Text>
                    <S.Text>ИП ЛЕОНОВИЧ ЭМИЛИЯ ВЛАДИМИРОВНА</S.Text>
                    <S.Text>ОГРНИП: 323246800115786</S.Text>
                    <S.Text>ИНН: 246312003224</S.Text>
                    <S.Text>E-mail: <a href="mailto:bonjour@frenchwithemilie.ru">bonjour@frenchwithemilie.ru</a></S.Text>
                    <S.Text>Редакция Оферты № 1 от 28.04.2023 г.</S.Text>
                    <S.Text>Предыдущие редакции:</S.Text>
                    <S.ButtonLink to={AppRoute.Rules}>Ссылка на правила оказания услуг</S.ButtonLink>
                </S.Wrapper>
            </Main>
            <Footer />
        </React.Fragment>
    )
}
