import React, { useEffect } from "react";
import * as S from '../../styles/default';
import Header from "../../components/header";
import Footer from "../../components/footer/footer";
import Main from "../../components/main";
import { AppRoute } from "../../const";


export default function MainScreen(): JSX.Element {
    useEffect(() => {
        document.title = 'Оферта. French withe Emillie'
    }, [])
    return (
        <React.Fragment>
            <Header background={false} />
            <Main>
                <S.Wrapper>
                    <S.TitleH1>ПРАВИЛА ОКАЗАНИЯ УСЛУГ</S.TitleH1>
                    <S.Text>Индивидуальный предприниматель ЛЕОНОВИЧ ЭМИЛИЯ ВЛАДИМИРОВНА, именуемый в дальнейшем Исполнитель, ОГРНИП: 323246800115786, ИНН: 246312003224, Дата присвоения ОГРНИП: 06.09.2023, использующий знак обслуживания «French With Emilie» адресует настоящие Правила любому лицу (неопределенному кругу лиц), чья воля будет выражена им лично либо через уполномоченного представителя (ст. 182, 185 Гражданского кодекса Российской Федерации), выразившему готовность принять предложение Исполнителя, на указанных ниже условиях. Настоящие Правила (далее по тексту – Правила) являются неотъемлемой частью Договора-оферты. Присоединение к Оферте обозначает согласие с настоящими Правилами. Присоединение к настоящим Правилам обозначает согласие к Договором-офертой.</S.Text>
                    <S.Text><strong>В соответствии с п. 2 ст. 437 Гражданского кодекса РФ (далее по тексту – ГК РФ) в случае принятия изложенных ниже условий и оплаты услуг, лицо, производящее акцепт этой оферты становится Заказчиком (в соответствии с п. 3 ст. 438 ГК РФ акцепт оферты равносилен заключению договора на условиях, изложенных в оферте), а Исполнитель и Заказчик совместно Сторонами договора.</strong></S.Text>
                    <S.Text>Публичная Оферта предполагает намерение Исполнителя оказывать услуги, предусмотренные настоящим Договором, всем лицам, обратившимся за оказанием услуг и принявшим все условия Оферты, в соответствии с настоящим Договором, кроме случаев, когда оказание услуг Исполнителем невозможно по техническим, правовым или иным причинам.</S.Text>
                    <S.TitleH3>1. 	ОБЩИЕ ПОЛОЖЕНИЯ</S.TitleH3>
                    <S.Text>ИП ТЛЕОНОВИЧ ЭМИЛИЯ ВЛАДИМИРОВНА, (далее и везде «Исполнитель»), использующий знак обслуживания «French With Emilie» оказывает услуги по предоставлению доступа к созданным Исполнителем информационным материалам для разных уровней знания французского языка, с уровня A1 до уровня C2, на платформе progressme.ru / edvibe.com.</S.Text>
                    <S.Text>Исполнитель оказывает дополнительные услуги по организации проведения уроков по этим материалам. Уроки проходят в формате видео звонка с применением сервисов Zoom или Bitrix24 или с помощью встроенного функционала платформы, с участием преподавателей которых привлекает Исполнитель.</S.Text>
                    <S.TitleH3>2. 	СТОИМОСТЬ И СРОКИ</S.TitleH3>
                    <S.Text>Стоимость и сроки занятия зависят от выбранного тарифа, согласно Тарифам, являющимся неотъемлемой частью Договора-Оферты и Правил оказания услуг.</S.Text>
                    <S.Text>Доступ к материалам на платформе (включая домашнее задания, доступ к интерактивной доске, пройденным урокам, чатам в классе, доступа к классу и т.п.) имеют пользователи, у которых баланс оплаченных занятий положительный, если баланс занятий нулевой, то доступ к материалам закрывается.</S.Text>
                    <S.Text>Баланс занятий можно пополнить от 1 шт. В этом случае после проведения урока, не будет доступа к материалам, включая домашнее задание.</S.Text>
                    <S.TitleH3>3. 	ОПЛАТА</S.TitleH3>
                    <S.Text>Для оплаты выставляется счет, где указано вид занятия, время, стоимость. </S.Text>
                    <S.Text>Оплатить можно с помощью QR кода в банках АО "ТИНЬКОФФ БАНК", ПАО "СБЕРБАНК РОССИИ", АО «Альфа-банк», ПАО «Совкомбанк» и сервиса предоставляемого ООО НКО «Юмани», или с помощью перевода на реквизиты Исполнителя, если банк плательщик Сторон отличается.</S.Text>
                    <S.TitleH3>4. 	РЕГИСТРАЦИЯ </S.TitleH3>
                    <S.Text>После того как Пользователь присоединился к настоящей Оферта и Правилам, Исполнитель отправляет Пользователю ссылку на форму «гугл докс», где Пользователь указывает свою персональную информацию, Имя Фамилия, почта, дата рождения, контактные данные и другую информацию, а также в этой форме он указывает, когда ему удобно проводить занятия и сколько раз в неделю. </S.Text>
                    <S.TitleH3>5. 	ВЫБОР УЧИТЕЛЯ</S.TitleH3>
                    <S.Text>После того как Исполнитель получил от Пользователя список предпочтительного времени проведения занятий (из формы «гугл докс»), Пользователю предоставляется список со свободными преподавателями в выбранное Пользователем время, после выбора учеником преподавателя и времени урока, преподаватель подтверждает это время Исполнителю и, в случае возможности Исполнитель назначает пользователю на платформе свободного учителя.</S.Text>
                    <S.Text>По инициативе школы преподаватель может быть изменен.</S.Text>
                    <S.Text>Также по инициативе Пользователя, Пользователь может выбрать другого преподавателя.</S.Text>
                    <S.Text>Если после проведения оплаченного занятия ученика не устроили материалы или преподаватель, денежные средства за проведенное занятие не возвращаются.</S.Text>
                    <S.TitleH3>6. 	РАСПИСАНИЕ</S.TitleH3>
                    <S.Text>При выборе учителя, Пользователь указывает свои свободные «слоты» и сколько раз хочет заниматься в неделю. расписание занятий пользователю отображается в личном кабинете. Расписание обновляется раз в неделю при наличии оплаченных уроков. </S.Text>
                    <S.Text>Пользователю высылается расписание на следующую неделю в пятницу до 19:00 по мск. </S.Text>
                    <S.TitleH3>7. 	ПЕРЕНОС ЗАНЯТИЙ</S.TitleH3>
                    <S.Text>Занятие можно бесплатно переносить/отменять при предварительном согласовании за 24 часа до начала занятия.</S.Text>
                    <S.Text>Перенос по инициативе Исполнителя возможен за 24 часа до даты занятия.</S.Text>
                    <S.Text>Если Пользователь не явился на урок или попросил о переносе менее чем за 24 часа до начала занятия, то урок с баланса списывается, услуга считается оказанной Исполнителем. </S.Text>
                    <S.Text>Если у Пользователя согласован график занятий - 1 занятие в неделю, то он имеет право переносить 1 занятие в месяц.</S.Text>
                    <S.Text>Если у Пользователя согласован график занятий – от двух до трех занятий в неделю, то он имеет право переносить 2 занятия в месяц.</S.Text>
                    <S.Text>Если у Пользователя согласован график занятий – от четырех и более занятий в неделю, то пользователь имеет право переносить 3 занятия в месяц.</S.Text>
                    <S.TitleH3>8. 	ЗАНЯТИЕ</S.TitleH3>
                    <S.Text>Если в установленное для занятия время Пользователь не появляется на уроке (не заходит в конференцию зум, битрикс24 или платформу, в зависимости от того где проходит занятие), преподаватель ожидает 10 минут от начала занятия, после чего покидает конференцию и занятие считается проведенным и оплачивается в полном размере.</S.Text>
                    <S.Text>При этом временем начала занятия считается установленное в графике занятий время начала занятия. </S.Text>
                    <S.Text>Если в установленное время начала занятия (плюс 5 минут) преподаватель не приходит на занятие, пользователь обязан немедленно сообщить об этом Исполнителю по электронной почте <a href="mailto:bonjour@frenchwithemilie.ru">bonjour@frenchwithemilie.ru</a> или в телеграмм <a href="tel:+79199943063">+7 (919) 994 3063</a>, (по графику Понедельник-Пятница 09.00-22.00). Занятия, которые не состоялись по вине преподавателя, переносятся в полном объеме на другое удобное для Клиента время.</S.Text>
                    <S.Text>Если у ученика плохое качества интернета, или не работает/плохо работает оборудование, то занятие не компенсируется,</S.Text>
                    <S.Text>Если у преподавателя плохое качества интернета или не работает/плохо работает оборудование, то занятие компенсируется за счет Исполнителя путем назначения замещающего занятия.</S.Text>
                    <S.TitleH3>9. 	ГРУППОВЫЕ ЗАНЯТИЯ</S.TitleH3>
                    <S.Text>Исполнитель (преподаватель) не обязаны повторять материал групповых занятий, пройденный во время отсутствия Пользователя по тем или иным причинам.</S.Text>
                    <S.Text>В группе ученики (иные пользователи) могут меняться, это никак не согласуется с остальными членами группы и Пользователем.</S.Text>
                    <S.Text>Если количества учеников в группе становится менее 3-х, то группа по инициативе Исполнителя может быть остановлена, до поиска нового ученика в группу, в это время баланс замораживается и не списывается, если в течении месяца не удалось найти новых учеников, то клиенту возвращаются деньги за остаток баланса, или же можно перейти в другие форматы занятия, индивидуальные или в паре.</S.Text>
                    <S.TitleH3>10. 	ИНЫЕ УСЛОВИЯ</S.TitleH3>
                    <S.Text>Каждый урок записывается. В спорных ситуациях можно запись уроков может быть предоставлена для возможности проверки информации, а также для целей повышения качества работы Исполнителя.</S.Text>
                    <S.Text>Уроки хранятся на серверах zoom и bitrix24</S.Text>
                    <S.Text>Все даты и время проведения уроков указываются по МСК. </S.Text>
                    <S.Text>Тарифы Исполнителя могут меняться, кроме случаев, когда уже пакет оплачен пользователем.</S.Text>
                    <S.Text>Учебные материалы являются интеллектуальной собственностью Исполнителя, их нельзя копировать или распространять.</S.Text>
                    <S.Text>Материалы на платформе открываются для Пользователя постепенно, по мере обучения, т.к. каждое занятие открывается новый урок.</S.Text>
                    <S.Text>Пользователь даёт согласия на хранение и обработку персональных данных, почта, телефон email и другую необходимую информацию.</S.Text>
                    <S.Text>Минимальные технические требования к оборудованию Пользователя:</S.Text>
                    <S.Text>Процессор: Intel Core i5 или аналоги;</S.Text>
                    <S.Text>Оперативная память: от 6 ГБ;</S.Text>
                    <S.Text>Интернет-соединение: от 30 Мбит/сек;</S.Text>
                    <S.Text>Наличие вебкамеры и гарнитуры с микрофоном;</S.Text>
                    <S.Text>Браузер: Google Chrome, последней версии.</S.Text>
                    <S.Text>Присоединяясь к настоящим Правилам, Пользователь подтверждает, что его устройство соответствует предъявленным выше характеристикам.</S.Text>
                    <S.Text>При настройке рабочего места пользователь должен закрыть все программы, которые могут занимать большую часть интернет-канала (такие как файлообменники). Для проведения занятия Пользователь должен обеспечить доступ к микрофону и камере на своем техническом устройстве.</S.Text>
                    <S.TitleH3>11. 	ПРОБНОЕ ЗАНЯТИЕ </S.TitleH3>
                    <S.Text>Для новых Пользователей, существует специальный тариф, пробное занятие, им можно воспользоваться сразу после присоединения к настоящей Оферте и Правилам, стоимость  пробного занятия составляет 500 рублей за 30 минут,</S.Text>
                    <S.Text>В пробное занятие входит 30 минут урока с преподавателем, на котором определяется уровень языка клиента, после пробного занятия в течении 3-х дней, дается обратная связь со списком рекомендаций, для достижения поставленной цели и определением уровня языка Пользователя.</S.Text>
                    <S.TitleH3>12. 	РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ И КОНТАКТНАЯ ИНФОРМАЦИЯ</S.TitleH3>
                    <S.Text>ИП ЛЕОНОВИЧ ЭМИЛИЯ ВЛАДИМИРОВНА</S.Text>
                    <S.Text>ОГРНИП: 323246800115786</S.Text>
                    <S.Text>ИНН: 246312003224</S.Text>
                    <S.Text>E-mail: <a href="mailto:bonjour@frenchwithemilie.ru">bonjour@frenchwithemilie.ru</a></S.Text>
                    <S.Text>Редакция Правил оказания услуг № 1 от 28.04.2023 г.</S.Text>
                    <S.Text>Предыдущие редакции:</S.Text>
                    <S.ButtonLink to={AppRoute.Contract}>Ссылка на оферту</S.ButtonLink>
                </S.Wrapper>
            </Main>
            <Footer />
        </React.Fragment>
    )
}
